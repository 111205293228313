export class Contenidos {
  id: number;
  tpContenido: number;
  link: string;
  titulo: string;
  retornaA: number;
  idRetornaA: number;
  idTemaConsulta: number;
  orden: number;
  codigo: string;
  orden2: number;
}
