export class Empresa {
  id: number;
  razonSocial: string;
  email: string;
  direccion: string;
  nit: string;
  nombreContacto: string;
  telefonoContacto: string;

}
