export class ReglasPregunta {
	id: number;
	idPregunta: number;
	tpCondicion: string;
	valor: string;
	minValor: string;
	maxValor: string;
	retornaA: number;
	idRetornaA: number;
}
